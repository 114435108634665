<template>
  <div class="message-box">
    <div class="main-box">
      <div class="side-left cursorP flexd" v-if="type == '1'">
        <div class="flexr lis">
          <img
              :src="basicList.imageurl"
              :class="basicList.imageurl ? '' : 'err_avatar'"
              alt=""
          />
          <h2>{{ basicList.userName }}</h2>
          <ul class="flexr">
            <li>
              {{ basicList.sex }}
              <el-divider direction="vertical"></el-divider>
            </li>
            <li>
              {{ basicList.age }}
              <el-divider direction="vertical"></el-divider>
            </li>
            <li>
              {{ basicList.edu }}
            </li>
          </ul>
          <ul
              class="flexr"
              v-for="(item, index) in basicList.disabledType"
              :key="index"
          >
            <li>
              {{ item.disabledType }}
              <el-divider direction="vertical"></el-divider>
            </li>
            <li>
              {{ item.disabledLevel }}
              <!-- <el-divider direction="vertical"></el-divider> -->
            </li>
            <li>
              <!-- {{ item.disabledDetail }} -->
            </li>
          </ul>
          <p>
            户籍地：{{ basicList.provinceid }}{{
              basicList.cityid
            }}{{ basicList.threeCityid }}
          </p>
        </div>
        <el-divider></el-divider>
        <ul class="flexr ris">
          <li>
            <p class="p1">{{ countList.LOOKS }}<span>家</span></p>
            <p class="p2">企业看过</p>
          </li>
          <li>
            <p class="p1">{{ countList.FEEDS }}<span>家</span></p>
            <p class="p2">企业反馈</p>
          </li>
        </ul>
      </div>
      <div class="side-left cursorP flexd" v-if="type == '2'">
        <div class="flexr lis">
          <img
              :src="comIndoLlist.logo"
              :class="comIndoLlist.logo ? '' : 'err_logo'"
              alt=""
              class="comimg"
          />
          <h2 class="line_clamp1">{{ comIndoLlist.companyName }}</h2>
          <p class="line_clamp2 comsp">
            {{ comIndoLlist.address }}
          </p>
        </div>
        <el-divider></el-divider>
        <ul class="flexr ris">
          <li>
            <p class="p1">{{ comIndoLlist.jobCounts || 0 }}<span>个</span></p>
            <p class="p2">在招职位</p>
          </li>
          <li>
            <p class="p1">{{ comIndoLlist.interviews || 0 }}<span>条</span></p>
            <p class="p2">面试邀请</p>
          </li>
        </ul>
      </div>
      <!-- 隔开 -->
      <div class="side-right" v-loading="loading">
        <div v-if="type == '1'">
          <ul class="flext tabs">
            <li @click="tabs(0)" :class="active == 0 ? 'active' : ''">
              全部<span class="paddL5" v-if="msgCount.zong > 0">{{
                msgCount.zong
              }}</span>
            </li>
            <li @click="tabs(1)" :class="active == 1 ? 'active' : ''">
              投递反馈<span class="paddL5" v-if="msgCount.touDi > 0">{{
                msgCount.touDi
              }}</span>
            </li>
            <li @click="tabs(2)" :class="active == 2 ? 'active' : ''">
              面试邀请<span class="paddL5" v-if="msgCount.yaoQing > 0">{{
                msgCount.yaoQing
              }}</span>
            </li>
            <li @click="tabs(3)" :class="active == 3 ? 'active' : ''" v-if="msgCount.xiTong">
              系统通知<span class="paddL5" v-if="msgCount.xiTong > 0">{{
                msgCount.xiTong
              }}</span>
            </li>
          </ul>
          <el-divider></el-divider>
          <ul class="all">
            <li v-for="item in messageList" :key="item.id">
              <div class="flext head">
                <p v-if="item.noticeType == 5">系统消息</p>
                <p v-if="item.noticeType == 6">投递反馈</p>
                <p v-if="item.noticeType == 7">职位邀请</p>
                <p class="ptime">{{ item.noticeDate }}</p>
              </div>
              <div class="flex imgs" v-if="item.noticeType == 5">
                <img
                    :src="item.companyLogo"
                    alt=""
                    v-if="item.companyLogo"
                    :class="item.companyLogo ? '' : 'err_logo'"
                />
                <div class="img_right flex">
                  <h3>{{ item.noticeMsgJson.msgJson }}</h3>
                  <router-link :to="item.path" v-if="item.path != null"
                  >去看看
                  </router-link
                  >
                </div>
              </div>
              <div class="flex imgs" v-else>
                <img
                    :src="item.companyLogo"
                    alt=""
                    v-if="item.companyLogo"
                    :class="item.companyLogo ? '' : 'err_logo'"
                />
                <div class="img_right flex">
                  <h3>{{ item.companyName }}</h3>
                  <p>
                    最新状态：<span>{{ item.noticeMsgJson.msgJson }}</span>
                  </p>
                  <router-link :to="item.path" v-if="item.path != null"
                  >去看看
                  </router-link
                  >
                </div>
              </div>
              <el-divider></el-divider>
            </li>
          </ul>
        </div>
        <div v-if="type == '2'">
          <ul class="flext tabs">
            <li>
              消息通知
            </li>
          </ul>
          <el-divider></el-divider>
          <ul class="all">
            <li v-for="item in messageList" :key="item.id">
              <div class="flext head">
                <p>系统通知</p>
                <p class="ptime">{{ item.noticeDate }}</p>
              </div>
              <div class="flex imgs">
                <div class="img_right flex">
                  <h3>{{ item.noticeMsgJson.msgJson }}</h3>
                  <router-link :to="item.path" v-if="item.path != null"
                  >去看看
                  </router-link
                  >
                </div>
              </div>
              <el-divider></el-divider>
            </li>
          </ul>
        </div>
        <div class="pages">
          <el-pagination
              background
              class="work_pagination"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.pageNum"
              :page-size="page.pageSize"
              layout="prev, pager, next, total, jumper"
              :total="page.total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: ["全部", "投递反馈", "职位邀请", "系统通知"],
      active: 0,
      no_message: 0,
      messageList: [],
      page: {
        pageNum: 1,
        pageSize: 6,
        total: 0,
        noticeType: "0",
      },
      loading: false,
      type: "1",
      basicList: [],
      countList: [],
      comIndoLlist: [],
      msgCount: {},
    };
  },
  methods: {
    tabs(i) {
      this.active = i;
      if (this.active == 1) {
        this.page.noticeType = 6;
      } else if (this.active == 2) {
        this.page.noticeType = 7;
      } else if (this.active == 3) {
        this.page.noticeType = 5;
      } else {
        this.page.noticeType = 0;
      }
      this.queryNoticesByTypeInfo();
    },
    getDisabledCountInfoFn() {
      this.$api
          .getDisabledCountInfo()
          .then((res) => {
            if (res.data.success) {
              this.countList = res.data.data;
              this.loading = false;
            } else {
              console.log("错误了", error);
              this.loading = false;
            }
          })
          .catch((error) => {
            console.log("错误了", error);
          });
    },
    async getComInfo() {
      let that = this;
      let res = await that.$api.comCenterBasicInfo();
      console.log(res.data.data);
      if (res.data.code === 200) {
        that.comIndoLlist = res.data.data;
      }
    },
    async getMyDisAbledInfo() {
      let res = await this.$api.getMyDisAbledInfo();
      if (res.data.success) {
        this.basicList = res.data.data;
      } else {
        console.log("错误了", error);
      }
    },
    //通知消息
    async queryNoticesByTypeInfo() {
      this.loading = true;

      try {
        let _this = this;
        let res = await _this.$api.queryNoticesByType(this.page);
        if (res.data.success) {
          _this.no_message = 1;
          _this.messageList = res.data.data.records;
          _this.page.total = res.data.data.total;
          _this.loading = false;
        } else {
          _this.loading = false;
          _this.no_message = 0;
        }
      } catch (error) {
        _this.loading = false;
        _this.no_message = 0;
      }
    },
    //一键已读
    read() {
      this.$api.queryUserMsgCount().then((res) => {
        console.log(res.data, "刷新成功！！");
        this.msgCount = res.data;
      });
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
      // this.$api.readApi({ id: id }).then((res) => {});
    },

    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.queryNoticesByTypeInfo();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    this.getMyDisAbledInfo();
    this.queryNoticesByTypeInfo();
    this.getDisabledCountInfoFn();
    this.getComInfo();
    this.read();
    if (this.$route.query.active) {
      this.active = this.$route.query.active;
    }
    let info = JSON.parse(localStorage.getItem('userType'));
    this.type = info.userType;
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/messages/index";

.comimg {
  width: 88px;
  height: 88px;
  border-radius: 5px;
  overflow: hidden;
}

.comsp {
  text-align: center;
}
</style>
